video {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

#adContainer {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'CeraRegular';
  src: url('https://static.prod.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.eot');
  src: url('https://static.prod.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('https://static.prod.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.woff') format('woff'),
    url('https://static.prod.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.woff2') format('woff2'),
    url('https://static.prod.kijk.nl/fonts/cera_pro_regular/CeraPro-Regular.ttf') format('truetype');
}

cast-media-player {
  --progress-color: hsl(203, 58%, 44%);
  --splash-image: url('/images/splash.jpg');
  --background-image: url('/images/background.jpg');
  --logo-image: url('/images/logo.png');
  --logo-repeat: no-repeat;
  --splash-size: cover;
  --ad-title: "Advertentie";
  --font-family: "CeraRegular";
  --watermark-image: url('/images/watermark.png');
}